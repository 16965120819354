// Constantes
import { ADDONS } from '@/constants'
// Components
import CardContainer from '@/components/ui/CardContainer'
import CardContainerElement from '@/components/ui/CardContainerElement'
import FormButtons from '@/components/ui/FormButtons'
import VuetifySchedules from '@/components/ui/VuetifySchedules'
import VuetifyToolBar from '@/components/ui/VuetifyToolBar'
// Mixins
import formMixin from '@/mixins/formMixin'
import uiMixin from '@/mixins/uiMixin'
import addonsMixin from '@/mixins/addonsMixin'
// Vuelidate plugin
import { validationMixin } from 'vuelidate'
// Vuex
import { mapActions, mapGetters } from 'vuex'
// Utils
import { get } from 'lodash'
// Filters
import { sanitizeHtmlContent } from '@/filters'

export default {
  name: 'ContactConfigSchedule',
  components: {
    CardContainer,
    CardContainerElement,
    FormButtons,
    VuetifySchedules,
    VuetifyToolBar
  },
  mixins: [formMixin, uiMixin, addonsMixin, validationMixin],
  data() {
    return {
      // Form
      formFields: {
        prevailPlaceScheduleOnMenusSchedule: true,
        opened: true,
        schedule: [],
        scheduleInformation: null
      },
      formFieldsValidations: {}
    }
  },
  computed: {
    ...mapGetters('place', ['placeData'])
  },
  mounted() {
    this.getEveryNeededData()
  },
  methods: {
    ...mapActions('place', ['updateConfigs']),
    /**
     * Evento lanzado tras la modificación del horario
     *
     * @param {Array} value - horarios
     */
    handleSchedule(value) {
      this.formFields.schedule = value
    },
    /**
     * Establece los valores inciales del formulario
     */
    getEveryNeededData() {
      this.setFormFieldsValues()
    },
    /**
     * Set inital values in formulary field to set the validation correctly
     */
    setFormFieldsValues() {
      const contactConfig = get(this.placeAddonsSetupByUser, ADDONS.contact, {})

      this.formFields = {
        prevailPlaceScheduleOnMenusSchedule: get(
          contactConfig,
          'prevailPlaceScheduleOnMenusSchedule',
          true
        ),
        opened: contactConfig.opened,
        schedule: contactConfig.schedule,
        scheduleInformation: sanitizeHtmlContent(contactConfig.scheduleInformation, [])
      }
    },
    /**
     * Is triggering after the form is correctly
     * validated by "Vuelidate"
     */
    async afterSubmit() {
      const { scheduleInformation, ...others } = this.formFields
      // Actualizamos configuraciones
      await this.updateConfigs({
        id: this.placeData.id,
        addOns: [
          {
            id: ADDONS.contact,
            configFields: {
              ...others,
              scheduleInformation: sanitizeHtmlContent(scheduleInformation, [])
            }
          }
        ]
      })

      // show info
      this.modifyAppAlert({
        text: 'Los cambios se guardaron correctamente',
        visible: true
      })
    }
  },
  validations: {
    formFields: {
      prevailPlaceScheduleOnMenusSchedule: {},
      opened: {},
      schedule: {},
      scheduleInformation: {}
    }
  }
}
